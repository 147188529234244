/**
 * Icons | Font Awesome by Dave Gandy | http://fontawesome.io
 * ----------------------------------------------------------------------------
 */

@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.6.2/css/font-awesome.min.css);

[class^="fa-"], [class*=" fa-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'fontawesome' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.fa-star:before {
  content: "\f005";
}
.fa-close:before {
  content: "\f00d";
}
.fa-map-marker:before {
  content: "\f041";
}
.fa-chevron-left:before {
  content: "\f053";
}
.fa-chevron-right:before {
  content: "\f054";
}
.fa-chevron-up:before {
  content: "\f077";
}
.fa-chevron-down:before {
  content: "\f078";
}
.fa-bar-chart:before {
  content: "\f080";
}
.fa-twitter:before {
  content: "\f099";
}
.fa-facebook:before {
  content: "\f09a";
}
.fa-feed:before {
  content: "\f09e";
}
.fa-link:before {
  content: "\f0c1";
}
.fa-navicon:before {
  content: "\f0c9";
}
.fa-google-plus:before {
  content: "\f0d5";
}
.fa-envelope:before {
  content: "\f0e0";
}
.fa-linkedin:before {
  content: "\f0e1";
}
.fa-github-alt:before {
  content: "\f113";
}
.fa-code:before {
  content: "\f121";
}
.fa-xing:before {
  content: "\f168";
}
.fa-youtube-play:before {
  content: "\f16a";
}
.fa-stack-overflow:before {
  content: "\f16c";
}
.fa-instagram:before {
  content: "\f16d";
}
.fa-flickr:before {
  content: "\f16e";
}
.fa-tumblr:before {
  content: "\f173";
}
.fa-dribbble:before {
  content: "\f17d";
}
.fa-weibo:before {
  content: "\f18a";
}
.fa-behance:before {
  content: "\f1b4";
}
.fa-soundcloud:before {
  content: "\f1be";
}
.fa-vine:before {
  content: "\f1ca";
}
.fa-codepen:before {
  content: "\f1cb";
}
.fa-weixin:before {
  content: "\f1d7";
}
.fa-pinterest-p:before {
  content: "\f231";
}
.fa-whatsapp:before {
  content: "\f232";
}
.fa-medium:before {
  content: "\f23a";
}
.fa-500px:before {
  content: "\f26e";
}
.fa-vimeo:before {
  content: "\f27d";
}
.fa-mixcloud:before {
  content: "\f289";
}



/**
 * Icons | Fontello | http://fontello.com
 * ----------------------------------------------------------------------------
 */

 @font-face {
  font-family: 'fontello';
  src: url('../fonts/fontello.eot?46433513');
  src: url('../fonts/fontello.eot?46433513#iefix') format('embedded-opentype'),
       url('../fonts/fontello.woff2?46433513') format('woff2'),
       url('../fonts/fontello.woff?46433513') format('woff'),
       url('../fonts/fontello.ttf?46433513') format('truetype'),
       url('../fonts/fontello.svg?46433513#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?46433513#fontello') format('svg');
  }
}
*/

 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-academia:before { content: '\e800'; } /* '' */
.icon-eth:before { content: '\e801'; } /* '' */
.icon-loop-manual:before { content: '\e802'; } /* '' */
.icon-mendeley:before { content: '\e803'; } /* '' */
.icon-orcid:before { content: '\e804'; } /* '' */
.icon-publons:before { content: '\e805'; } /* '' */
.icon-thomson-reuters:before { content: '\e806'; } /* '' */
.icon-um:before { content: '\e807'; } /* '' */
.icon-unu:before { content: '\e808'; } /* '' */
.icon-uzh:before { content: '\e809'; } /* '' */
.icon-erfurt:before { content: '\e80a'; } /* '' */
.icon-emo-happy:before { content: '\e80b'; } /* '' */
.icon-emo-wink:before { content: '\e80c'; } /* '' */
.icon-emo-unhappy:before { content: '\e80d'; } /* '' */
.icon-emo-sleep:before { content: '\e80e'; } /* '' */
.icon-emo-thumbsup:before { content: '\e80f'; } /* '' */
.icon-emo-devil:before { content: '\e810'; } /* '' */
.icon-emo-surprised:before { content: '\e811'; } /* '' */
.icon-emo-tongue:before { content: '\e812'; } /* '' */
.icon-emo-wink2:before { content: '\e813'; } /* '' */
.icon-emo-coffee:before { content: '\e814'; } /* '' */
.icon-emo-sunglasses:before { content: '\e815'; } /* '' */
.icon-emo-displeased:before { content: '\e816'; } /* '' */
.icon-emo-beer:before { content: '\e817'; } /* '' */
.icon-emo-grin:before { content: '\e818'; } /* '' */
.icon-emo-angry:before { content: '\e819'; } /* '' */
.icon-emo-saint:before { content: '\e81a'; } /* '' */
.icon-emo-cry:before { content: '\e81b'; } /* '' */
.icon-emo-shoot:before { content: '\e81c'; } /* '' */
.icon-emo-squint:before { content: '\e81d'; } /* '' */
.icon-emo-laugh:before { content: '\e81e'; } /* '' */
