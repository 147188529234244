/**
 * Variables
 * ----------------------------------------------------------------------------
 */

/* Original Colors */
$alabaster: #fafafa;
$gallery: #ededed;
$gray: #818181;
$mine-shaft: #333333;
$picton-blue: #22b3eb;
$silver: #c6c6c6;
$tundora: #474747;
$white: #ffffff;

/* Light Colors
$body-text: #818181;
$heading-text: lighten( $body-text, 4% );
$links-and-boxes: #2980b9;
$light-emphasis: #ecf0f1;
$dark-emphasis: #95a5a6;
$dark-blue: #2c3e50;
$text-bg: #ffffff;
 */

/* Dark Colors */
$body-text: #ecf0f1;
$heading-text: darken( $body-text, 4% );
$links-and-boxes: #3498db;
$light-emphasis: #2c3e50;
$dark-emphasis: #ecf0f1;
$dark-blue: #2c3e50;
$text-bg: #34495e;
